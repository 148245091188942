<template>
  <div>
    <div v-if="getBottler" class="bottler-header">
      <img
        v-if="getBottler.files_attachments.length > 0"
        :src="getBottler.files_attachments[0]"
        :alt="getBottler.name"
      />
      <article class="bottler-header-card">
        <h1>{{ getBottler.name }}</h1>
        <div v-html="getBottler.description" class="bottler-header-card-content"></div>
      </article>
      <div class="bottler-header-searchbar">
        <search
          @search="searchProducts"
          :origin="'bottler'"
          :searchLoad="searchLoad"
          :pageKey="'bottlerPage'"
        />
      </div>
    </div>
    <div class="bottler-body">
      <searchBottler />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import search from '@/components/modules/search_V2.vue'
import searchBottler from '@/components/modules/bottler/search.vue'
import { ElMessage } from 'element-plus'

export default {
  name: 'bottler',
  components: {
    search,
    searchBottler
  },
  data() {
    return {
      searchLoad: false,
      search: '',
      payload: {
        type: 'bottlerPage',
        search: '',
        filters: {
            spirit_type: null,
            country: null,
            age: null,
            vintage: null,
            single_cask: false
        }
      }
    }
  },
  async mounted() {
    await this.bottler(this.$route.params.name).then(() => {
      this.searchProducts(this.getBottler?.name, 'bottlerPage');
    })
  },
  computed: {
    ...mapGetters({
      getBottler: 'getBottler',
      getSearchPending: 'getSearchPending',
      isAuth: 'getIsAuth',
    }),
  },
  methods: {
    ...mapActions({
      bottler: 'bottler',
      autoSearchAuth: 'autocompleteSearchAuth',
    }),
    searchProducts(searchProducts, key) {
      this.conditionsSearch = 0;
      this.search += searchProducts

      if (searchProducts.total) {
        this.payload.filters = searchProducts.filters;
      }

      this.payload.search = this.search
      this.payload.spirit_type = searchProducts.spirit_type

      if (!this.payload.filters) {
        this.payload.filters = {
          spirit_type: null,
          country: null,
          age: null,
          vintage: null,
          single_cask: false
        }
      }

      if (searchProducts.value?.length > 0 || searchProducts.search?.length > 0) {
        this.conditionsSearch += 2;
      }

      if (this.payload.filters.age) {
        this.conditionsSearch += 1;
      }

      if (this.payload.filters.single_cask) {
        this.conditionsSearch += 1;
      }

      if (this.payload.filters.spirit_type) {
        this.conditionsSearch += 1;
      }

      if (this.payload.filters.vintage) {
        this.conditionsSearch += 1;
      }

      if (this.payload.filters.country) {
        if (this.payload.filters.country.length > 0) {
          this.conditionsSearch += 1;
        }
      }

      if (this.conditionsSearch === 1) {
        ElMessage({
          message: this.$t('filters.filters_conditions'),
          type: 'warning',
          timeout: 5000,
          grouping: true,
        })
      }

      setTimeout(() => {
        if (!this.getSearchPending['bottlerPage']) {
          const formattedPayload = {
            type: key,
            search: this.search,
            spirit_type: searchProducts.spirit_type,
            filters: this.payload.filters,
            bottler: this.getBottler?.name
          }

          formattedPayload.search = `${this.getBottler?.name}${searchProducts.value ? ' ' + searchProducts.value : ''}`;

          if (this.origin === 'bottler') {
            formattedPayload.bottler = this.getBottler?.name;
            formattedPayload.type = 'bottlerPage';
          }

          this.last_pending_search = searchProducts

          if (this.isAuth === true) {
            this.searchLoad = true
            this.autoSearchAuth(formattedPayload).then(() => {
              this.searchLoad = false
            })
          } else {
            this.dialogSearchNotAccess = true

            ElMessage({
              message: this.$t('search.connect_access_search'),
              type: 'warning',
              timeout: 5000,
              grouping: true,
            })
          }
        }
      }, 400);
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.bottler-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/img/search_background.webp);
  background-size: cover;
  padding: 20px 0;
  gap: 15px;

  img {
    max-width: 250px;
  }

  &-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    font-weight: 500;
    border: 1px solid var(--bg-color-bottler);
    border-radius: 12px;
    width: 100%;
    max-width: 892px;
    background: url(../assets/svg/v2/home/background.svg) 45% 45%;
    background-size: 150%;
    background-color: var(--bg-color-bottler);
    padding: 20px 10px;
    h1 {
      text-align: center;
      font-size: 32px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 5px;
      letter-spacing: 0.01rem;
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 17px;
      }
    }
  }
}

@media (max-width: 768px) {
  .bottler-header {
    gap: 10px;
    max-height: none;
    &-card {
      width: 87%;
      gap: 25px;
      background-size: 300%;
      h1 {
        font-size: 24px;
      }
      &-content {
        font-weight: 400;
      }
    }
  }
}
</style>
